// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js!../../components/styles/constants.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tag-item {\n  display: inline-block;\n  margin: 10px 20px 10px 0;\n}\n\n.tag-item .tag-name {\n  display: inline;\n  margin-right: 5px;\n}\n\n.tag-item .tag-delete {\n  display: inline;\n}\n", "",{"version":3,"sources":["webpack://./client/src/admin/styles/TagItem.css"],"names":[],"mappings":"AAEA;EACE,qBAAqB;EACrB,wBAAwB;AAC1B;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB","sourcesContent":["@import '../../components/styles/constants.css';\n\n.tag-item {\n  display: inline-block;\n  margin: 10px 20px 10px 0;\n}\n\n.tag-item .tag-name {\n  display: inline;\n  margin-right: 5px;\n}\n\n.tag-item .tag-delete {\n  display: inline;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
