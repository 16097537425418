// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js!../../styles/constants.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".posts .wm-spreads {\n  background-color: var(--background-color-light-secondary);\n  overflow: hidden;\n  text-align: left;\n  padding: var(--post-list-margin);\n}\n\n.posts .wm-spreads.mobile {\n  padding-right: 0;\n}\n\n.posts .image-scroll-item img {\n  height: 400px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/pages/styles/AllPostsPage.css"],"names":[],"mappings":"AAEA;EACE,yDAAyD;EACzD,gBAAgB;EAChB,gBAAgB;EAChB,gCAAgC;AAClC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf","sourcesContent":["@import '../../styles/constants.css';\n\n.posts .wm-spreads {\n  background-color: var(--background-color-light-secondary);\n  overflow: hidden;\n  text-align: left;\n  padding: var(--post-list-margin);\n}\n\n.posts .wm-spreads.mobile {\n  padding-right: 0;\n}\n\n.posts .image-scroll-item img {\n  height: 400px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
