// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js!../../components/styles/constants.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tag-manager {\n  padding: var(--post-list-margin);\n}\n\n.tag-manager .tag-bar {\n  display: block;\n  width: 100%;\n  height: 50px;\n}\n\n.tag-manager .tag-title {\n  font-family: var(--main-title-font);\n  float: left;\n  margin: 0;\n  font-size: 1.5rem;\n  color: var(--background-color-dark-main);\n}\n\n.tag-manager .add-tag {\n  font-family: var(--caption-font);\n  float: left;\n  margin: 15px 0 0 30px;\n  font-size: 0.8rem;\n  color: var(--background-color-dark-main);\n}\n\n.tag-manager .tag-holder {\n  display: block;\n  min-height: 100px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/admin/styles/TagManager.css"],"names":[],"mappings":"AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,cAAc;EACd,WAAW;EACX,YAAY;AACd;;AAEA;EACE,mCAAmC;EACnC,WAAW;EACX,SAAS;EACT,iBAAiB;EACjB,wCAAwC;AAC1C;;AAEA;EACE,gCAAgC;EAChC,WAAW;EACX,qBAAqB;EACrB,iBAAiB;EACjB,wCAAwC;AAC1C;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB","sourcesContent":["@import '../../components/styles/constants.css';\n\n.tag-manager {\n  padding: var(--post-list-margin);\n}\n\n.tag-manager .tag-bar {\n  display: block;\n  width: 100%;\n  height: 50px;\n}\n\n.tag-manager .tag-title {\n  font-family: var(--main-title-font);\n  float: left;\n  margin: 0;\n  font-size: 1.5rem;\n  color: var(--background-color-dark-main);\n}\n\n.tag-manager .add-tag {\n  font-family: var(--caption-font);\n  float: left;\n  margin: 15px 0 0 30px;\n  font-size: 0.8rem;\n  color: var(--background-color-dark-main);\n}\n\n.tag-manager .tag-holder {\n  display: block;\n  min-height: 100px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
