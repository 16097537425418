// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js!../../styles/constants.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footer {\n  margin: 0;\n  height: var(--footer-height);\n  background-color: var(--background-color-light-secondary);\n}\n\n.footer .reacting-link {\n  text-align: center;\n  display: inline-block;\n  margin: 40px 10px 40px 10px;\n}\n\n.footer .reacting-link:hover {\n  transform: scale(1.2);\n  -webkit-transform: scale(1.2);\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/main-components/styles/Footer.css"],"names":[],"mappings":"AAEA;EACE,SAAS;EACT,4BAA4B;EAC5B,yDAAyD;AAC3D;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,2BAA2B;AAC7B;;AAEA;EACE,qBAAqB;EACrB,6BAA6B;AAC/B","sourcesContent":["@import '../../styles/constants.css';\n\n.footer {\n  margin: 0;\n  height: var(--footer-height);\n  background-color: var(--background-color-light-secondary);\n}\n\n.footer .reacting-link {\n  text-align: center;\n  display: inline-block;\n  margin: 40px 10px 40px 10px;\n}\n\n.footer .reacting-link:hover {\n  transform: scale(1.2);\n  -webkit-transform: scale(1.2);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
