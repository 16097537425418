// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js!../../styles/constants.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".post-span {\n  display: inline-block;\n}\n\n.post-span span {\n  display: inline;\n}\n\n.actual-post-span {\n  display: inline-block;\n  font-size: 1.5rem;\n  transition: 0.5s ease;\n}", "",{"version":3,"sources":["webpack://./client/src/components/main-components/styles/PostSpan.css"],"names":[],"mappings":"AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,qBAAqB;AACvB","sourcesContent":["@import '../../styles/constants.css';\n\n.post-span {\n  display: inline-block;\n}\n\n.post-span span {\n  display: inline;\n}\n\n.actual-post-span {\n  display: inline-block;\n  font-size: 1.5rem;\n  transition: 0.5s ease;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
