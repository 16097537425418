// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js!../../styles/constants.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n  --builder-bar-height: 50px;\n}\n\n.builder-bar {\n  margin: 0;\n  background-color: var(--background-color-mid-main);\n  color: black;\n  height: var(--builder-bar-height);\n  border: 1px solid gray;\n  position: sticky;\n  top: var(--nav-bar-height);\n  display: grid;\n  grid-template-columns: 30% 1fr 1fr 1fr 1fr 30%;\n  z-index: 99;\n}\n\n@media screen and (max-width: 1200px) {\n  .builder-bar {\n    grid-template-columns: 20% 1fr 1fr 1fr 1fr 20%;\n  }\n}\n\n@media screen and (max-width: 800px) {\n  .builder-bar {\n    grid-template-columns: 10px 1fr 1fr 1fr 1fr 10px;\n  }\n}\n\n.builder-bar-item .expand-cursor {\n  display: inline-block;\n  text-align: center;\n  line-height: var(--builder-bar-height);\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/builder-components/styles/BuilderBar.css"],"names":[],"mappings":"AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,SAAS;EACT,kDAAkD;EAClD,YAAY;EACZ,iCAAiC;EACjC,sBAAsB;EACtB,gBAAgB;EAChB,0BAA0B;EAC1B,aAAa;EACb,8CAA8C;EAC9C,WAAW;AACb;;AAEA;EACE;IACE,8CAA8C;EAChD;AACF;;AAEA;EACE;IACE,gDAAgD;EAClD;AACF;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,sCAAsC;AACxC","sourcesContent":["@import '../../styles/constants.css';\n\n:root {\n  --builder-bar-height: 50px;\n}\n\n.builder-bar {\n  margin: 0;\n  background-color: var(--background-color-mid-main);\n  color: black;\n  height: var(--builder-bar-height);\n  border: 1px solid gray;\n  position: sticky;\n  top: var(--nav-bar-height);\n  display: grid;\n  grid-template-columns: 30% 1fr 1fr 1fr 1fr 30%;\n  z-index: 99;\n}\n\n@media screen and (max-width: 1200px) {\n  .builder-bar {\n    grid-template-columns: 20% 1fr 1fr 1fr 1fr 20%;\n  }\n}\n\n@media screen and (max-width: 800px) {\n  .builder-bar {\n    grid-template-columns: 10px 1fr 1fr 1fr 1fr 10px;\n  }\n}\n\n.builder-bar-item .expand-cursor {\n  display: inline-block;\n  text-align: center;\n  line-height: var(--builder-bar-height);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
